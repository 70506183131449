import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/AdminPage.css';

const AdminPage = () => {
  const [reviews, setReviews] = useState([]);
  const [selectedResponseFiles, setSelectedResponseFiles] = useState({});
  const [message, setMessage] = useState('');

  // Получение всех заявок (требуется, чтобы только администратор имел доступ к этому эндпоинту)
  const fetchReviews = async () => {
    try {
      const response = await axios.get('https://diplore.ru/api/reviews', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setReviews(response.data);
    } catch (error) {
      console.error('Ошибка при получении заявок:', error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  // Обработка выбора файла для ответа
  const handleResponseFileChange = (e, reviewId) => {
    setSelectedResponseFiles({
      ...selectedResponseFiles,
      [reviewId]: e.target.files[0]
    });
  };

  // Отправка файла-ответа для конкретной заявки
  const handleUploadResponse = async (reviewId) => {
    const file = selectedResponseFiles[reviewId];
    if (!file) {
      setMessage('Выберите файл для загрузки.');
      return;
    }
    const formData = new FormData();
    formData.append('responseFile', file);
    try {
      await axios.put(`https://diplore.ru/api/reviews/${reviewId}/response`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setMessage('Ответ успешно загружен!');
      // Обновляем список заявок после успешной загрузки
      fetchReviews();
    } catch (error) {
      console.error('Ошибка при загрузке ответа:', error);
      setMessage('Ошибка при загрузке ответа.');
    }
  };

  return (
    <div className="admin-page">
      <h1>Админ панель — Управление проверками</h1>
      {message && <p className="message">{message}</p>}
      <table className="reviews-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Пользователь</th>
            <th>Название заявки</th>
            <th>Описание</th>
            <th>Файл заявки</th>
            <th>Статус</th>
            <th>Ответ редакции</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {reviews.map(review => (
            <tr key={review.id}>
              <td>{review.id}</td>
              <td>{review.user_id}</td>
              <td>{review.title}</td>
              <td>{review.description}</td>
              <td>
                {review.file_path ? (
                  <a href={`https://diplore.ru/${review.file_path}`} target="_blank" rel="noopener noreferrer">
                    Скачать
                  </a>
                ) : 'Нет файла'}
              </td>
              <td>{review.status}</td>
              <td>
                {review.response_file_path ? (
                  <a href={`https://diplore.ru/${review.response_file_path}`} target="_blank" rel="noopener noreferrer">
                    Скачать ответ
                  </a>
                ) : 'Нет ответа'}
              </td>
              <td>
                {review.status === 'pending' && (
                  <>
                    <input
                      type="file"
                      onChange={(e) => handleResponseFileChange(e, review.id)}
                      accept=".pdf"
                    />
                    <button onClick={() => handleUploadResponse(review.id)}>Загрузить ответ</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPage;
