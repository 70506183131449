import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getOwnProfile } from '../services/api';
import '../styles/Header.css';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showToolsSubmenu, setShowToolsSubmenu] = useState(false);
  const [showJournalSubmenu, setShowJournalSubmenu] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Функция для управления видимостью шапки при скролле
  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  // Получение данных о текущем пользователе
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getOwnProfile();
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Если произошла ошибка (например, токен просрочен), очищаем его
        localStorage.removeItem('token');
        setUser(null);
      }
    };

    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData();
    }
  }, []);

  // Переключение мобильного меню
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    document.body.style.overflow = isMobileMenuOpen ? 'auto' : 'hidden';
  };

  // Обработка перехода на страницу входа
  const handleLoginClick = () => {
    navigate('/login');
  };

  // Обработка выхода пользователя
  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  };

  // Функция для получения инициалов пользователя
  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  return (
    <>
      <header className={`header ${!isVisible ? 'hidden' : ''}`}>
        <nav>
          <Link to="/" className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="40" height="40">
              <rect x="20" y="20" width="160" height="160" fill="#F5E6D3" rx="20" ry="20" />
              <text x="100" y="100" fontFamily="Arial, sans-serif" fontSize="140" textAnchor="middle" dy=".3em" fill="#333333">"</text>
            </svg>
            <span className="logo-text">Diplore</span>
          </Link>
          <ul className="desktop-menu">
            <li 
              onMouseEnter={() => setShowToolsSubmenu(true)}
              onMouseLeave={() => setShowToolsSubmenu(false)}
            >
              <Link to="/tools">Инструменты</Link>
              {showToolsSubmenu && (
                <div className="submenu tools-submenu">
                  <Link to="/tools/quote-generator">Генератор цитат</Link>
                  <Link to="/tools/source-generator">Генератор источников</Link>
                  <Link to="/tools/tutor-plus">Тьютор+</Link>
                  <Link to="/tools/create-survey">Создать опрос</Link>
                </div>
              )}
            </li>
            <li
              onMouseEnter={() => setShowJournalSubmenu(true)}
              onMouseLeave={() => setShowJournalSubmenu(false)}
            >
              <Link to="/journal">Научный журнал</Link>
              {showJournalSubmenu && (
                <div className="submenu journal-submenu">
                  <Link to="/journal">Журнал</Link>
                  <Link to="/journal/submit">Попасть в выпуск</Link>
                  <Link to="/journal/archive">Архив выпусков</Link>
                  <Link to="/journal/editorial-board">Редакционная коллегия</Link>
                </div>
              )}
            </li>
            <li><Link to="/search">Источники</Link></li>
            <li><Link to="/catalog">Каталог</Link></li>
            <li><Link to="/blog">Блог</Link></li>
            {/* Новая ссылка на страницу проверок */}
            <li><Link to="/review">Проверки</Link></li>
            {user && (
              <li><Link to={`/profile/${user.id}`}>Мой профиль</Link></li>
            )}
            {user && user.role === 'admin' && (
              <li><Link to="/admin">Админ панель</Link></li>
            )}
          </ul>
          <div className="user-actions">
            {user ? (
              <>
                <Link to={`/profile/${user.id}`} className="profile-icon">
                  {user.avatar_color ? (
                    <div 
                      className="user-avatar"
                      style={{ 
                        backgroundColor: user.avatar_color,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        fontWeight: 'bold'
                      }}
                    >
                      {getInitials(user.first_name + ' ' + user.last_name)}
                    </div>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
                      <circle cx="12" cy="12" r="10"/>
                    </svg>
                  )}
                </Link>
                <button className="logout-button" onClick={handleLogout}>Выход</button>
              </>
            ) : (
              <button className="login-icon" onClick={handleLoginClick}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
                  <polyline points="10 17 15 12 10 7" />
                  <line x1="15" y1="12" x2="3" y2="12" />
                </svg>
              </button>
            )}
          </div>
          <button 
            className={`mobile-menu-toggle ${isMobileMenuOpen ? 'open' : ''}`} 
            onClick={toggleMobileMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </nav>
      </header>
      <div className={`mobile-menu-overlay ${isMobileMenuOpen ? 'open' : ''}`}>
        <div className="mobile-menu-header">
          <Link to="/" className="logo" onClick={toggleMobileMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="40" height="40">
              <rect x="20" y="20" width="160" height="160" fill="#F5E6D3" rx="20" ry="20"/>
              <text x="100" y="100" fontFamily="Arial, sans-serif" fontSize="140" textAnchor="middle" dy=".3em" fill="#333333">"</text>
            </svg>
            <span className="logo-text">Diplore</span>
          </Link>
          <button className="close-menu" onClick={toggleMobileMenu}>
            <span></span>
            <span></span>
          </button>
        </div>
        <nav className="mobile-menu">
          <Link to="/tools" onClick={toggleMobileMenu}>Инструменты</Link>
          <div className="mobile-submenu">
            <Link to="/tools/quote-generator" onClick={toggleMobileMenu}>Генератор цитат</Link>
            <Link to="/tools/source-generator" onClick={toggleMobileMenu}>Генератор источников</Link>
            <Link to="/tools/tutor-plus" onClick={toggleMobileMenu}>Тьютор+</Link>
            <Link to="/tools/create-survey" onClick={toggleMobileMenu}>Создать опрос</Link>
          </div>
          <Link to="/journal" onClick={toggleMobileMenu}>Научный журнал</Link>
          <div className="mobile-submenu">
            <Link to="/journal/submit" onClick={toggleMobileMenu}>Попасть в выпуск</Link>
            <Link to="/journal/archive" onClick={toggleMobileMenu}>Архив выпусков</Link>
            <Link to="/journal/editorial-board" onClick={toggleMobileMenu}>Редакционная коллегия</Link>
          </div>
          <Link to="/search" onClick={toggleMobileMenu}>Источники</Link>
          <Link to="/catalog" onClick={toggleMobileMenu}>Каталог</Link>
          <Link to="/blog" onClick={toggleMobileMenu}>Блог</Link>
          {/* Ссылка на страницу проверок */}
          <Link to="/review" onClick={toggleMobileMenu}>Проверки</Link>
          {user && (
            <Link to={`/profile/${user.id}`} onClick={toggleMobileMenu}>Мой профиль</Link>
          )}
          {user && user.role === 'admin' && (
            <Link to="/admin" onClick={toggleMobileMenu}>Админ панель</Link>
          )}
          {user && (
            <button className="logout-button" onClick={handleLogout}>Выход</button>
          )}
        </nav>
      </div>
      {(showToolsSubmenu || showJournalSubmenu) && <div className="blur-overlay"></div>}
    </>
  );
};

export default Header;
