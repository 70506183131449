import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getProfile, updateProfile, getUserReviews } from '../services/api';
import './Profile.css';

const Profile = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState(null);

  const avatarColors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581', '#7986CB'];

  // Загрузка данных пользователя
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getProfile(userId);
        setUser(userData);
        setEditedUser(userData);
        setLoading(false);
      } catch (err) {
        setError('Не удалось получить данные пользователя');
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  // Загрузка заявок на проверку пользователя
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsData = await getUserReviews();
        setReviews(reviewsData);
      } catch (err) {
        console.error('Ошибка при получении заявок на проверку:', err);
      }
    };

    if (user) {
      fetchReviews();
    }
  }, [user]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedUser(user);
  };

  const handleSave = async () => {
    try {
      const updatedUser = await updateProfile(userId, editedUser);
      setUser(updatedUser);
      setIsEditing(false);
    } catch (err) {
      setError('Не удалось сохранить изменения');
    }
  };

  const handleChange = (e) => {
    setEditedUser({ ...editedUser, [e.target.name]: e.target.value });
  };

  const handleAvatarColorChange = (color) => {
    setEditedUser({ ...editedUser, avatar_color: color });
  };

  if (loading) return <div className="loading">Загрузка...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!user) return <div className="error">Данные пользователя не найдены</div>;

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div 
          className="profile-avatar"
          style={{ backgroundColor: user.avatar_color || '#000000' }}
        >
          {user.first_name && user.last_name 
            ? `${user.first_name[0]}${user.last_name[0]}` 
            : user.username[0]}
        </div>
        <h1>{`${user.first_name} ${user.last_name}`}</h1>
      </div>
      <div className="profile-content">
        <div className="profile-section">
          <h2>Основная информация</h2>
          {isEditing ? (
            <>
              <input name="first_name" value={editedUser.first_name} onChange={handleChange} placeholder="Имя" />
              <input name="last_name" value={editedUser.last_name} onChange={handleChange} placeholder="Фамилия" />
              <input name="middle_name" value={editedUser.middle_name} onChange={handleChange} placeholder="Отчество" />
              <input name="email" value={editedUser.email} onChange={handleChange} placeholder="Email" />
              <div className="avatar-color-selection">
                {avatarColors.map(color => (
                  <div
                    key={color}
                    className={`avatar-color-option ${editedUser.avatar_color === color ? 'selected' : ''}`}
                    style={{ backgroundColor: color }}
                    onClick={() => handleAvatarColorChange(color)}
                  />
                ))}
              </div>
            </>
          ) : (
            <>
              <p><strong>Имя:</strong> {user.first_name}</p>
              <p><strong>Фамилия:</strong> {user.last_name}</p>
              <p><strong>Отчество:</strong> {user.middle_name}</p>
              <p><strong>Email:</strong> {user.email}</p>
            </>
          )}
        </div>
        <div className="profile-section">
          <h2>Научная деятельность</h2>
          {isEditing ? (
            <>
              <input name="institution" value={editedUser.institution} onChange={handleChange} placeholder="Учебное заведение" />
              <select name="education_level" value={editedUser.education_level} onChange={handleChange}>
                <option value="">Выберите уровень образования</option>
                <option value="bachelor">Бакалавр</option>
                <option value="master">Магистр</option>
                <option value="phd">Кандидат наук</option>
                <option value="doctorate">Доктор наук</option>
              </select>
              <textarea name="research_interests" value={editedUser.research_interests} onChange={handleChange} placeholder="Научные интересы" />
              <input name="orcid_id" value={editedUser.orcid_id} onChange={handleChange} placeholder="ORCID ID" />
            </>
          ) : (
            <>
              <p><strong>Учебное заведение:</strong> {user.institution}</p>
              <p><strong>Уровень образования:</strong> {user.education_level}</p>
              <p><strong>Научные интересы:</strong> {user.research_interests}</p>
              <p><strong>ORCID ID:</strong> {user.orcid_id}</p>
            </>
          )}
        </div>
        <div className="profile-section">
          <h2>О себе</h2>
          {isEditing ? (
            <textarea name="bio" value={editedUser.bio} onChange={handleChange} placeholder="Биография" />
          ) : (
            <p>{user.bio}</p>
          )}
        </div>
        {/* Новый раздел для заявок на проверку */}
        <div className="profile-section">
          <h2>Мои заявки на проверку</h2>
          {reviews.length > 0 ? (
            <ul>
              {reviews.map(review => (
                <li key={review.id}>
                  <strong>{review.title}</strong> – статус: {review.status}
                  <br />
                  {review.file_path ? (
                    <span>
                      <a href={`https://diplore.ru/${review.file_path}`} target="_blank" rel="noopener noreferrer">
                        Скачать заявку
                      </a>
                    </span>
                  ) : (
                    <span> (Файл заявки отсутствует)</span>
                  )}
                  <br />
                  {review.response_file_path ? (
                    <span>
                      <a href={`https://diplore.ru/${review.response_file_path}`} target="_blank" rel="noopener noreferrer">
                        Скачать ответ
                      </a>
                    </span>
                  ) : (
                    <span> (Ответ не предоставлен)</span>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>Нет поданных заявок на проверку</p>
          )}
        </div>
      </div>
      <div className="profile-footer">
        <p><strong>Дата регистрации:</strong> {new Date(user.join_date).toLocaleDateString()}</p>
        <p><strong>Последний вход:</strong> {user.last_login_date ? new Date(user.last_login_date).toLocaleString() : 'Нет данных'}</p>
      </div>
      <div className="profile-actions">
        {isEditing ? (
          <>
            <button onClick={handleSave}>Сохранить изменения</button>
            <button onClick={handleCancel}>Отмена</button>
          </>
        ) : (
          <button onClick={handleEdit}>Редактировать профиль</button>
        )}
      </div>
    </div>
  );
};

export default Profile;
