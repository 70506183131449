// File: frontend/src/services/api.js

import axios from 'axios';

const API_BASE_URL = 'https://diplore.ru/api'; // Замените на ваш реальный URL API

const getAuthHeader = () => ({
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
});

export const searchSources = async (query) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/search`, { query });
    return response.data;
  } catch (error) {
    console.error('Error searching sources:', error.response?.data || error.message);
    throw error;
  }
};

export const getProfile = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/profile/${userId}`, getAuthHeader());
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error.response?.data || error.message);
    throw error;
  }
};

export const updateProfile = async (userId, profileData) => {
  try {
    console.log('Updating profile with data:', profileData); // Для отладки
    const response = await axios.put(`${API_BASE_URL}/user/profile/${userId}`, profileData, getAuthHeader());
    console.log('Profile update response:', response.data); // Для отладки
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error.response?.data || error.message);
    throw error;
  }
};

export const getOwnProfile = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/me`, getAuthHeader());
    return response.data;
  } catch (error) {
    console.error('Error fetching own profile:', error.response?.data || error.message);
    throw error;
  }
};

export const updateOwnProfile = async (profileData) => {
  try {
    console.log('Updating own profile with data:', profileData); // Для отладки
    const response = await axios.put(`${API_BASE_URL}/user/me`, profileData, getAuthHeader());
    console.log('Own profile update response:', response.data); // Для отладки
    return response.data;
  } catch (error) {
    console.error('Error updating own profile:', error.response?.data || error.message);
    throw error;
  }
};

export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/register`, userData);
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error.response?.data || error.message);
    throw error;
  }
};

export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/login`, credentials);
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserReviews = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/reviews/my`, getAuthHeader());
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении заявок пользователя:', error.response?.data || error.message);
    throw error;
  }
};

// Новая функция для обновления цвета аватара
export const updateAvatarColor = async (userId, color) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/user/avatar-color/${userId}`, { avatar_color: color }, getAuthHeader());
    return response.data;
  } catch (error) {
    console.error('Error updating avatar color:', error.response?.data || error.message);
    throw error;
  }
};

// Вы можете добавить здесь другие функции API по мере необходимости