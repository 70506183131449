import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/SubmitReviewPage.css';

const SubmitReviewPage = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    file: null
  });
  const [message, setMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [reviews, setReviews] = useState([]);

  // Функция для получения заявок пользователя
  const fetchReviews = async () => {
    try {
      const response = await axios.get('https://diplore.ru/api/reviews/my', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setReviews(response.data);
    } catch (error) {
      console.error('Ошибка при получении заявок:', error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  // Обработчик изменений в полях формы
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: files ? files[0] : value
    }));
  };

  // Обработчик отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setUploadProgress(0);

    // Проверка размера файла (максимум 50 MB)
    if (formData.file && formData.file.size > 50 * 1024 * 1024) {
      setMessage('Файл слишком большой. Максимальный размер - 50 MB.');
      return;
    }

    const dataToSend = new FormData();
    dataToSend.append('title', formData.title);
    dataToSend.append('description', formData.description);
    if (formData.file) {
      dataToSend.append('file', formData.file);
    }

    try {
      const response = await axios.post('https://diplore.ru/api/reviews', dataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      setMessage('Заявка на проверку успешно отправлена!');
      // Очищаем форму
      setFormData({
        title: '',
        description: '',
        file: null
      });
      const fileInput = document.getElementById('file');
      if (fileInput) fileInput.value = '';
      setUploadProgress(0);
      // Обновляем список заявок
      fetchReviews();
    } catch (error) {
      console.error('Ошибка при отправке заявки:', error);
      setMessage('Произошла ошибка при отправке заявки. Пожалуйста, попробуйте ещё раз.');
      setUploadProgress(0);
    }
  };

  // Анимация появления элементов с классом .animate-in
  useEffect(() => {
    const animateElements = () => {
      const elements = document.querySelectorAll('.animate-in');
      elements.forEach((el, index) => {
        setTimeout(() => {
          el.style.opacity = '1';
          el.style.transform = 'translateY(0)';
        }, 200 * index);
      });
    };
    animateElements();
  }, []);

  return (
    <div className="submit-review-page">
      <h1 className="page-title animate-in">Подать заявку на проверку</h1>
      <p className="page-subtitle animate-in">
        Загрузите вашу статью для проверки и получите ответ редакции
      </p>
      
      <form onSubmit={handleSubmit} className="animate-in">
        <div className="form-group">
          <label htmlFor="title">Название заявки</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="description">Описание заявки</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        
        <div className="form-group">
          <label htmlFor="file">Прикрепить файл (PDF, макс. 50 MB)</label>
          <input
            type="file"
            id="file"
            name="file"
            onChange={handleChange}
            accept=".pdf"
          />
        </div>
        
        {uploadProgress > 0 && uploadProgress < 100 && (
          <div className="upload-progress">
            Загрузка: {uploadProgress}%
            <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
          </div>
        )}
        
        {message && (
          <div className={message.includes('успешно') ? 'success-message' : 'error-message'}>
            {message}
          </div>
        )}
        
        <button type="submit" className="btn">Отправить заявку</button>
      </form>

      {/* Секция отображения отправленных заявок */}
      <div className="reviews-list animate-in">
        <h2>Мои заявки на проверку</h2>
        {reviews.length > 0 ? (
          <ul>
            {reviews.map(review => (
              <li key={review.id}>
                <strong>{review.title}</strong> — статус: {review.status}
                <div>
                  {review.file_path ? (
                    <a href={`https://diplore.ru/${review.file_path}`} target="_blank" rel="noopener noreferrer">
                      Скачать заявку
                    </a>
                  ) : (
                    <span>Файл заявки отсутствует</span>
                  )}
                </div>
                <div>
                  {review.response_file_path ? (
                    <a href={`https://diplore.ru/${review.response_file_path}`} target="_blank" rel="noopener noreferrer">
                      Скачать ответ редакции
                    </a>
                  ) : (
                    <span>Ответ не предоставлен</span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>Нет поданных заявок на проверку</p>
        )}
      </div>
    </div>
  );
};

export default SubmitReviewPage;
